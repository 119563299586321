<template>
    <b-row class="mb-5">
        <b-col cols="12">
            <b-card class="shadow" :no-body="noBody" :body-class="cardBodyClass">
                <b-button v-if="foldable"
                          class="foldable-pill"
                          variant="outline-secondary"
                          pill size="sm"
                          @click="showCollapse = !showCollapse">
                    <font-awesome-icon icon="chevron-left" :rotation="showCollapse ? 270 : null"/>
                </b-button>
                <div class="bg-transparent" v-if="!noHeader" slot="header">
                    <b-card-title :class="{ 'mb-0': !subtitle }">
                        <slot name="title">
                            {{ title }}
                        </slot>
                    </b-card-title>
                    <b-card-sub-title v-if="subtitle">
                        <slot name="subtitle">
                            {{ subtitle }}
                        </slot>
                    </b-card-sub-title>
                </div>
                <b-collapse :id="randomString()"
                            v-model="showCollapse"
                            @hidden="cardBodyClass = 'noVerticalPadding'"
                            @show="cardBodyClass = ''">
                    <slot/>
                </b-collapse>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import {randomString} from '@/util/text';

    export default {
        name: "ClujiCard",
        props: {
            noBody: {
                type: Boolean,
                default: false
            },
            startsOpen: {
                type: [Boolean, Number],
                default: true
            },
            title: {
                type: String,
                default: ''
            },
            subtitle: {
                type: String,
                default: ''
            },
            foldable: {
                type: Boolean,
                default: true
            },
            noHeader: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            cardBodyClass: '',
            showCollapse: true
        }),
        methods: {randomString},
        watch: {
            startsOpen(val) {
                this.startsOpen = val;
                this.showCollapse = Boolean(val);
                this.$emit('collapse', val);
            },
            showCollapse(val) {
                this.showCollapse = val;
                this.$emit('collapse', val);
            }
        },
        mounted() {
            this.showCollapse = Boolean(this.startsOpen);
        }
    }
</script>

<style scoped>
    div.noVerticalPadding {
        padding-top: 0;
        padding-bottom: 0;
    }

    .foldable-pill {
        z-index: 1;
        position: absolute;
        right: .9rem;
        top: 1.1rem;
    }

    div.card {
        min-height: 3.7rem;
    }

    .btn-outline-secondary {
        border-color: #dee2e6;
    }

    .btn-outline-secondary:hover {
        border-color: transparent;
    }
</style>